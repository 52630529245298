import React, {Component} from 'react';
import lib from 'library'

const styles = {
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container: {
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd'
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx'
  }
}

class SectionGallery extends Component {

  render() {
    return (<div style={styles.footerStyle}>
      <div style={styles.container}>

        {
          this.props.array && this.props.array.map((item) => {
            return (<img src={item} alt={item}/>)

          })
        }

      </div>

    </div>)
  }
}

export default SectionGallery
