
const langs = ['zh', 'en', 'gb']

export function currentLang (pathname) {
  var paths = pathname.split('/')
  var lang = langs[0]
  if(paths[1]){
     if(paths[1].length == 2){
       lang = paths[1]
     }
   }
   //console.log('lang '+lang)
   return lang;
}


export function langPath (toPath, lang) {

  if(lang !== langs[0]){
    toPath = '/'+lang+toPath
   }
   //console.log('toPath '+toPath)
   return toPath;
}

export function changeLang (pathname, toLang) {

  var paths = pathname.split('/')
  paths.shift(); paths.pop();
  if(paths[0]){
    if(paths[0].length == 2){ paths.shift() }
  }
  var page = '/'+toLang + '/'
  if(toLang == langs[0] || toLang == null){
    page = '/'
  }
  paths.map((item) => {
    page = page + item + '/'
  })
  return page;

}
