import React, {Component} from 'react';
import lib from 'library'



const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
    padding: 15,
  }

}


class SectionCompanyInfo extends Component {


  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
              minWidth: 350,
            }}>
            <p style={styles.content}>{lc[lang].info1}
              <br></br>
              <br></br>
              {lc[lang].info2}
              <br></br>
              <br></br>
              {lc[lang].info3}</p>
          </div>
          <div style={{
              width: 350,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img src={require('assets/image/KSlogo2.jpg')} alt='KSlogo' />
          </div>



        </div>

      </div>
    )
  }
}


const lc = {
  en:{
    info1: 'Kun Shung Holdings Limited, established in 2014, highly specialise in bags & luggage industry with a strong logistics organization. We have accessed to strong bags & luggage ISO-certified manufacturers in Asia Pacific and is producing goods for top international brands, including but not limited to Totto, Modrec and Samurai.',
    info2: 'Since the establishment, Kun Shung has been developing at a rapid rate every year, and the products were sold every year, and the products were sold globally.',
    info3: 'It mainly produces and sells travel bags and sports series of various world top brands. Its customers include HERITAGE, HOLIDAY GROUP, HEAD, PRO BAGS, RADU, SAMSONITE, TOTTO, VALIZA, VICTORINOX, WENGER, etc. The products are sold to North America, South America and Europe, Asia and other countries and regions. It is one of the largest luggage sales companies in Hong Kong. ',
  },
  zh:{
    info1: '冠信控股有限公司成立於2014年，主要業務是代工生產箱包、背包和旅行配件。',
    info2: '公司自創辦以後，每年都以較快的速度發展，經營範圍不斷擴大。',
    info3: '主要生產銷售各種世界頂尖品牌的旅行系列箱包和運動系列，客戶如HERITAGE、HOLIDAY GROUP、HEAD、PRO BAGS、RADU、SAMSONITE、TOTTO、VALIZA、VICTORINOX、WENGER等，產品遠銷北美、南美及歐洲、亞洲等國家和地區。是香港最大的箱包銷售企業之一。',

  }
}

/*
info1: 'Kun Shung Holdings Limited was established in 2014 and the main business is production of luggage, backpacks, bags and travel accessories.',
info2: 'Since its establishment, the company has been developing at a rapid rate every year, and its business scope has continuously expanded.',
info3: 'It mainly produces and sells travel bags and sports series of various world top brands. Its customers include HERITAGE, HOLIDAY GROUP, HEAD, PRO BAGS, RADU, SAMSONITE, TOTTO, VALIZA, VICTORINOX, WENGER, etc. The products are sold to North America, South America and Europe, Asia and other countries and regions. It is one of the largest luggage sales companies in Hong Kong. ',

*/


export default SectionCompanyInfo
