import React, {Component} from 'react';
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import lib from 'library'

import Header from 'components/Header'
import CopyrightBar from 'components/CopyrightBar'
import Footer from 'components/Footer'
import './index.css'

class Layout extends Component {
  render(){

    return(
      <div>
        <Helmet
          title={'Kun Shung Holdings Limited'}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        />
      <Header pathname={this.props.pathname} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: lib.st.WIDTH_PAGE,
            padding: '0px',
            paddingTop: 0,
            minHeight: 900
          }}
        >
          {this.props.children}
        </div>
      <Footer pathname={this.props.pathname} />
      <CopyrightBar pathname={this.props.pathname} />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
