import React, {Component} from 'react';
import lib from 'library'

import FooterAbout from './FooterAbout'
import FooterLinks from './FooterLinks'
import FooterContact from './FooterContact'


const styles ={
  footerStyle : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
    backgroundColor: lib.st.COLOR_THEME,
  }
}


class Footer extends Component {
  render(){
    return(
      <div style={styles.footerStyle}>
        <div style={{
          width: lib.st.WIDTH_PAGE,
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
        }} >

        <FooterAbout pathname={this.props.pathname} />
        <FooterLinks pathname={this.props.pathname} />
        <FooterContact pathname={this.props.pathname} />

        </div>
      </div>
    )
  }
}


export default Footer
