import React, {Component} from 'react';
import lib from 'library'
import HeaderLink from './HeaderLink'


const styles ={
  footerStyle : {
    display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center',
    flexDirection: 'column',
    height: 250,
    width: 300,
    padding: 10,
    //backgroundColor: 'lightgray',
  },
  title: {
    color: 'white',
    fontSize: 20,
    marginBottom: 20,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'white',
    fontSize: 16,
    marginBottom: 0,
    fontFamily: 'HelveticaNeueLTStd-Lt',
  }
}


class FooterLinks extends Component {

  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <p style={styles.title} >{lc[lang].link}</p>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
          }}>

          <div style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}>
            <HeaderLink color={'white'} title={lc[lang].home} link={lib.lc.langPath('/', lang)} />
            <HeaderLink color={'white'} title={lc[lang].aboutus} link={lib.lc.langPath('/aboutus', lang)} />
            <HeaderLink color={'white'} title={lc[lang].products} link={lib.lc.langPath('/products', lang)} />
            <HeaderLink color={'white'} title={lc[lang].information} link={lib.lc.langPath('/information', lang)} />
            <HeaderLink color={'white'} title={lc[lang].contactus} link={lib.lc.langPath('/contactus', lang)} />


          </div>

          <div style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}>
            <HeaderLink color={'white'} title={lc[lang].another} link={lib.lc.changeLang(this.props.pathname, lc[lang].path)} />
          </div>

        </div>

      </div>
    )
  }
}


const lc = {
  en:{
    home: 'Home',
    aboutus: 'About us',
    products: 'Products',
    information: 'Information',
    contactus: 'Contact us',
    another: '中文',
    path: 'zh',
    link: 'Links',
  },
  zh:{
    home: '首頁',
    aboutus: '關於我們',
    products: '產品',
    information: '資訊',
    contactus: '聯絡我們',
    another: 'English',
    path: 'en',
    link: '連結',
  }
}

export default FooterLinks
