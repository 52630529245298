import React, {Component} from 'react';
import Link from 'gatsby-link'

import lib from 'library'
import HeaderLink from 'components/HeaderLink'
import MediaQuery from 'react-responsive'


class Header extends Component {

  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={{
        height: 100,
        backgroundColor:  lib.st.COLOR_THEME,
        display: 'flex',
        justifyContent: 'center',
      }}>
          <div style={{
            width: lib.st.WIDTH_PAGE,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

          }} >
              <div style={{
                //backgroundColor: 'lightgray',
                width: 100,
                display: 'flex',

              }}>
                <img src={require('assets/image/KSlogo.jpg')} alt='KSlogo' />
              </div>

              <div style={{flex: 1}}>

              </div>

              <MediaQuery query="(min-device-width: 700px)">

                <div style={{
                  display: 'flex',

                }}>
                  <HeaderLink title={lc[lang].home} link={lib.lc.langPath('/', lang)} />
                  <HeaderLink title={lc[lang].aboutus} link={lib.lc.langPath('/aboutus', lang)} />
                  <HeaderLink title={lc[lang].products} link={lib.lc.langPath('/products', lang)} />
                  <HeaderLink title={lc[lang].information} link={lib.lc.langPath('/information', lang)} />
                  <HeaderLink title={lc[lang].contactus} link={lib.lc.langPath('/contactus', lang)} />
                  <HeaderLink title={lc[lang].another} link={lib.lc.changeLang(this.props.pathname, lc[lang].path)} />

                </div>

              </MediaQuery>

          </div>

      </div>
    )
  }
}

const lc = {
  en:{
    home: 'Home',
    aboutus: 'About us',
    products: 'Products',
    information: 'Information',
    contactus: 'Contact us',
    another: '中文',
    path: 'zh',
  },
  zh:{
    home: '首頁',
    aboutus: '關於我們',
    products: '產品',
    information: '資訊',
    contactus: '聯絡我們',
    another: 'En',
    path: 'en',
  }
}

export default Header
