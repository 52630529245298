import React, {Component} from 'react';
import lib from 'library'


const styles ={
  footerStyle : {
    display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center',
    flexDirection: 'column',
    height: 250,
    width: 300,
    padding: 10,
    //backgroundColor: 'lightgray',
  },
  title: {
    color: 'white',
    fontSize: 20,
    //marginBottom: 0,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'white',
    fontSize: 16,
    marginBottom: 0,
    fontFamily: 'HelveticaNeueLTStd-Lt',
  }
}


class FooterAbout extends Component {

  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <p style={styles.title} >{lc[lang].aboutus}</p>
        <p style={styles.title} >{lc[lang].companyname}</p>
        <p style={styles.content} >{lc[lang].companyinfo}</p>
      </div>
    )
  }
}


const lc = {
  en:{
    aboutus: 'About us',
    companyname: 'Kun Shung Holdings Limited',
    companyinfo: 'Kun Shung Holdings Limited was established in 2014 and the main business is production of luggage, backpacks, bags and travel accessories.',
  },
  zh:{
    aboutus: '關於我們',
    companyname: '冠信控股有限公司',
    companyinfo: '冠信控股有限公司成立於2014年，主要業務是代工生產箱包、背包和旅行配件。',
  }
}


export default FooterAbout
