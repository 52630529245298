
import * as st from './styles'
import * as pr from './parameter'
import * as lc from './localize'

const lib = {
  st: st,
  pr: pr,
  lc: lc,

};


export default lib;
