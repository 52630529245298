import React, {Component} from 'react';
import lib from 'library'


const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  title: {
    color: lib.st.COLOR_THEME,
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  }
}


class TitleBar extends Component {


  render(){
    return(
      <div style={styles.footerStyle}>
          <p style={styles.title}>{this.props.title}</p>
      </div>
    )
  }
}

export default TitleBar
