import React, {Component} from 'react';
import lib from 'library'


const footerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 50,
  backgroundColor: lib.st.COLOR_THEME2,
  fontFamily: 'HelveticaNeueLTStd-Lt',
}


class CopyrightBar extends Component {

  returnCurrentYear(){
    var current = new Date()
    return current.toISOString().slice(0,4);
  }

  render(){
    return(
      <div style={footerStyle}>
          <p style={{color: 'white', marginBottom: 0,}}>{'Copyright '+this.returnCurrentYear()+' Kun Shung Holdings Limited'}</p>
      </div>
    )
  }
}

export default CopyrightBar
