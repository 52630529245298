import React, {Component} from 'react';
import lib from 'library'



const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
    padding: 15,
  }
}


class SectionMissions extends Component {


  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
              minWidth: 350,
            }}>
            <p style={styles.content}>{lc[lang].info1}
              <br></br>
              <br></br>
              {lc[lang].info2}
              <br></br>
              <br></br>
              {lc[lang].info3}</p>
          </div>
          <div style={{
              width: 350,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img src={require('assets/image/TravelPlus.jpg')}  alt='TravelPlus' />
          </div>



        </div>

      </div>
    )
  }
}

const lc = {
  en:{
    info1: '- Offer quality and comprehensive products to our customers',
    info2: '- Share efforts with our staff',
    info3: '- Build long-term relationship with our suppliers',
  },
  zh:{
    info1: '- 為我們的客戶提供優質和全面的產品',
    info2: '- 與我們的員工共同努力',
    info3: '- 與供應商建立長期合作關係',

  }
}


export default SectionMissions
