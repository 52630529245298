import React, {Component} from 'react';
import Link from 'gatsby-link'
import Radium from 'radium'



class HeaderLink extends Component {

    render(){
      return(

          <Link to={this.props.link} style={{
              textDecoration: 'none' ,
            }} >
            <p style={{
              marginRight: 15,
              marginBottom: 0,
              color: this.props.color ||  'white', //'rgba(53,107,179,1)',
              fontSize: 20,
              textDecoration: 'none',
              fontFamily: 'HelveticaNeueLTStd-ThEx',
              ':hover': {
                color: 'rgba(57,169,71,1)',
                textDecoration: 'underline'
              }
            }}>{this.props.title}</p>
          </Link>

      )
  }
}

export default Radium(HeaderLink)
